import React from 'react';

export const AboutSection = () => {
  return (
    <section id="about" className="bg-black text-white border-l border-r border-b border-neon-green py-20 pb-32">
      <div className="container px-6 mx-auto">
        <h2 className="text-3xl md:text-4xl font-tetris mb-4 text-center md:text-left">
          About Us
        </h2>
        <p className="text-base md:text-lg mx-auto py-10">
          <span className="font-tetris text-sm md:text-base text-neon-green">At Narcizëm</span>, we don't just create music videos; we craft audacious visual anthems that flaunt your artistry and style. Rooted in the raw energy of the streets, our team of renegade directors, edgy cinematographers, and trailblazing editors push the boundaries of conventional storytelling. We embrace the bold, the brash, and the unapologetically narcissistic, <span className="font-tetris text-sm md:text-base text-neon-green">celebrating your music</span> in its most authentic and unfiltered form.
        </p>
        <p className="text-base md:text-lg mx-auto pb-10">
          <span className="font-tetris text-sm md:text-base text-neon-green">At Narcizëm</span>, every frame is a statement, every shot a rebellion—because your story deserves nothing less than legendary. At Narcizem, we specialize in producing stunning, high-quality music videos and commercials that captivate and engage. Using state-of-the-art cameras and advanced editing techniques, our team ensures every project is visually impeccable. Our expertise extends to cutting-edge 3D animation, bringing your vision to life with unmatched precision and creativity. <span className="font-tetris text-sm md:text-base text-neon-green">With Narcizem</span>, your videos will not only meet but exceed industry standards, leaving a lasting impact on your audience.
        </p>
        <p className="text-base md:text-lg mx-auto">
          <span className="font-tetris text-sm md:text-base text-neon-green">At Narcizem</span>, we turn your vision into high-quality music videos and commercials that hit hard. Using <span className="font-tetris text-sm md:text-base text-neon-green">top-notch cameras</span> and slick editing with the smoothest 3D animation, we bring your ideas to life with a unique style and unmatched creativity.
        </p>
        <div className="relative z-10 flex justify-center md:float-right mt-8 md:mt-4">
          <a href="#contact" className="retro-btn">
            Contact Us
          </a>
        </div>
      </div>
    </section>
  );
};