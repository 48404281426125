import React from 'react';
import crewImage1 from '../img/mario.gif';
import crewImage2 from '../img/luigi.gif';
import crewImage3 from '../img/yoshi.gif';
import crewImage4 from '../img/wario.gif';
import crewImage5 from '../img/toad.gif';
import crewImage6 from '../img/browser.gif';
import crewImage7 from '../img/peach.gif';

export const CrewSection = () => {
  const crewMembers = [
    { id: 1, name: 'olt.mp4', role: 'Founder/Director', image: crewImage1 },
    { id: 2, name: 'bleon.mp4', role: 'Director/3D Artist', image: crewImage5 },
    { id: 3, name: 'rimi.mp4', role: 'DOP/Editor', image: crewImage2 },
    { id: 4, name: 'etnik.mp4', role: 'Director', image: crewImage4 },
    { id: 5, name: 'dushi.jpg', role: 'Designer', image: crewImage3 },
    { id: 6, name: 'dren.jpg', role: 'Designer', image: crewImage6 },
    { id: 7, name: 'areo.mp3', role: 'Producer', image: crewImage7 },
  ];

  return (
    <section id="crew" className="bg-custom-bg text-white py-20 border-l border-r border-neon-green">
      <div className="container px-6 mx-auto">
        <h2 className="text-3xl md:text-4xl font-tetris mb-8 text-center md:text-left">
          Crew
        </h2>

        {/* Crew Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {crewMembers.map((member) => (
            <div 
              key={member.id} 
              className="bg-black border-4 border-neon-green p-4 flex flex-col items-center space-y-4 text-center retro-square transition-transform duration-300 hover:scale-105 hover:border-white hover:shadow-neon hover:bg-neon-green/10"
            >
              <img 
                src={member.image} 
                alt={member.name} 
                className="w-24 h-24 md:w-32 md:h-32 object-cover border border-neon-green"
              />
              <h3 className="text-lg md:text-xl font-tetris text-white">{member.name}</h3>
              <p className="text-neon-green text-xs md:text-sm font-tetris">{member.role}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};