import React, { useState } from 'react';
import { HeroSection } from '../components/HeroSection';
import { AboutSection } from '../components/AboutSection';
import { CrewSection } from '../components/CrewSection';
import Navbar from '../components/Navbar';
import LoadingScreen from '../components/LoadingScreen';
import ContactForm from '../components/ContactForm';
import { ArtistsSection } from '../components/ArtistsSection';
import { CollabsSection } from '../components/CollabsSection';
import Footer from '../components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function App() {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading ? (
        <LoadingScreen setLoading={setLoading} />
      ) : (
        <div className="bg-black">
          <Navbar />
          <HeroSection />
          <AboutSection />
          <CrewSection />
          <ArtistsSection />
          <CollabsSection />
          <ContactForm />
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;