import React, { useState } from 'react';
import { motion } from 'framer-motion';
import '@fontsource/press-start-2p'; 
import logo from '../img/logo-2.png';

const bounceAnimation = {
  initial: { y: -200, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { type: 'spring', stiffness: 100, damping: 10 } },
};

const LoadingScreen = ({ setLoading }) => {
  const [startPressed, setStartPressed] = useState(false);
  const [hovered, setHovered] = useState(null);

  const handlePressStart = () => {
    setStartPressed(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      {startPressed && (
        <>
          <motion.div
            initial={{ height: '0%' }}
            animate={{ height: '50%' }}
            transition={{ duration: 1, ease: 'easeInOut' }}
            className="fixed top-0 left-0 w-full bg-black z-50"
          />
          <motion.div
            initial={{ height: '0%' }}
            animate={{ height: '50%' }}
            transition={{ duration: 1, ease: 'easeInOut' }}
            className="fixed bottom-0 left-0 w-full bg-black z-50"
          />
        </>
      )}

      <motion.div
        initial={{ y: 0 }}
        transition={{ duration: 1 }}
        className="fixed inset-0 flex justify-center items-center bg-custom-bg"
      >
        <motion.div
          className="relative flex flex-col justify-center items-center w-full h-full"
          initial="initial"
          animate="animate"
        >
          {!startPressed ? (
            <>
              <motion.div variants={bounceAnimation} className="text-center mb-8 text-white font-tetris">
                <h2 className="text-md mb-4">Rules</h2>
                <p
                  className={`text-xs flex items-center justify-center transition-colors duration-300 ${hovered === 1 ? 'text-neon-green' : ''}`}
                  onMouseEnter={() => setHovered(1)}
                  onMouseLeave={() => setHovered(null)}
                >
                  {hovered === 1 && <span className="mr-2">➜</span>}
                  #1: NEVER GO AGAINST NARCIZEM
                </p>
                <p
                  className={`text-xs flex items-center justify-center transition-colors duration-300 ${hovered === 2 ? 'text-neon-green' : ''}`}
                  onMouseEnter={() => setHovered(2)}
                  onMouseLeave={() => setHovered(null)}
                >
                  {hovered === 2 && <span className="mr-2">➜</span>}
                  #2: WHEN IN DOUBT ALWAYS REFER TO RULE #1
                </p>
              </motion.div>

              <motion.button
                onClick={handlePressStart}
                variants={bounceAnimation}
                className="text-white font-tetris flex items-center space-x-4 transition-colors duration-300 hover:text-neon-green"
                whileHover={{ scale: 1.1 }}
              >
                <span className="text-lg rotate-90">▲</span>
                <span>Press Start</span>
                <span className="text-lg rotate-90">▲</span>
              </motion.button>

              <motion.div variants={bounceAnimation} className="absolute bottom-0 w-full flex justify-center">
                <img
                  src={logo}
                  alt="Logo"
                  className="w-32 h-auto"
                />
              </motion.div>
            </>
          ) : null}
        </motion.div>
      </motion.div>
    </>
  );
};

export default LoadingScreen;