import React from 'react';

export const HeroSection = () => {

  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section id="hero" className="relative bg-black text-white flex flex-col justify-center items-center px-6 pb-40 pt-20 overflow-hidden border-l border-r border-neon-green">
    {/* Background GIF */}
    <img
      src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExYWN4dmgweHVsM3hsempqeWZyMDdqYXZ1amh0OXRxbnN1djQxbm1qdSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/axgJo3ybJonkq8fOR0/giphy.gif"
      alt="Background Animation"
      className="absolute top-0 left-0 w-full h-full object-cover z-0 opacity-60"
    />

    {/* Content */}
    <div className="relative z-10 w-full container py-6 flex justify-center text-center pt-48">
      <h1 className="text-4xl md:text-5xl text-white font-tetris typing-loop">
        <span className="block md:hidden">Narcizem</span> {/* Display 'Narcizem' on small screens */}
        <span className="hidden md:block">This is Narcizem</span> {/* Full text on medium and larger screens */}
      </h1>
    </div>

      <p className="text-sm mb-10 z-10 rainbow-text font-tetris text-center md:text-left">
        Your new home of videos. Bring your craziest ideas to life.
      </p>

      {/* Buttons */}
      <div className="relative z-10 flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-6 mt-4 w-full justify-center max-w-6xl">
        <a 
          href="#about" 
          className="retro-btn w-fit mx-auto md:mx-0 md:w-auto text-center"
          onClick={(e) => handleSmoothScroll(e, 'about')}
        >
          Learn More
        </a>
        <a 
          href="#contact" 
          className="retro-btn w-fit mx-auto md:mx-0 md:w-auto text-center"
          onClick={(e) => handleSmoothScroll(e, 'contact')}
        >
          Contact Us
        </a>
      </div>
    </section>
  );
};