import React from 'react';
import { GrContact } from 'react-icons/gr'; // Contact icon
import logo from '../img/logo-2.png'; // Adjust the path to your logo

export const Footer = () => {

  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="bg-black text-white py-10 border-t border-neon-green font-tetris">
      <div className="container mx-auto px-6">
        {/* Footer Top - Logo and Navigation */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          {/* Logo Section */}
          <div className="flex justify-center md:justify-start">
            <img src={logo} alt="Narcizem Logo" className="h-20 md:h-24" />
          </div>

          {/* Navigation Section */}
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <h4 className="text-lg md:text-xl mb-4 text-neon-green">Navigation</h4>
            <ul className="space-y-2 text-xs md:text-sm">
              <li>
                <a href="#hero" className="hover:text-neon-green" onClick={(e) => handleSmoothScroll(e, 'hero')}>Home</a>
              </li>
              <li>
                <a href="#about" className="hover:text-neon-green" onClick={(e) => handleSmoothScroll(e, 'about')}>About</a>
              </li>
              <li>
                <a href="#crew" className="hover:text-neon-green" onClick={(e) => handleSmoothScroll(e, 'crew')}>Crew</a>
              </li>
              <li>
                <a href="#collabs" className="hover:text-neon-green" onClick={(e) => handleSmoothScroll(e, 'collabs')}>Collaborations</a>
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div className="flex flex-col items-center md:items-end text-center md:text-right">
            <h4 className="text-lg md:text-xl mb-4 text-neon-green">Contact</h4>
            <p className="text-xs md:text-sm">
              <a href="mailto:narcizemfilms@gmail.com" className="hover:text-neon-green">narcizemfilms@gmail.com</a>
            </p>
            <p className="text-xs md:text-sm flex items-center space-x-2">
              <GrContact size={18} />
              <a href="#contact" className="hover:text-neon-green" onClick={(e) => handleSmoothScroll(e, 'contact')}>Get in Touch</a>
            </p>
          </div>
        </div>

        {/* Footer Bottom - Credits */}
        <div className="border-t border-neon-green pt-6 flex flex-col md:flex-row justify-between text-center">
          <p className="text-xs md:text-sm">
            © {new Date().getFullYear()} Narcizem. All rights reserved.
          </p>
          <p className="text-xs md:text-sm text-neon-green mt-4 md:mt-0">
            Developed by <a href="https://github.com/toskashala" target="_blank" className="rainbow-text hover:text-white" rel="noreferrer">Toska</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;